import React, { useState, useCallback } from "react"
import * as DiscogsUtils from "../helpers/discogs-utils"
import * as Cache from "../helpers/cache"
import { useMediaQuery, useTheme } from "@material-ui/core"
import GridLayout from "../components/grid-layout"
import ItemDrawer from "../components/item-drawer"
import LoadingBackdrop from "../components/loading-backdrop"
import {
  InstanceReleaseEntry,
  InstanceReleaseList,
} from "../helpers/discogs-types"
import { graphql, useStaticQuery } from "gatsby"
import Page from "../components/page"
import { Helmet } from "react-helmet"

interface GetOpts {
  cursor?: string
}

const getReleases = async (opts: GetOpts) => {
  const cursor = opts.cursor || ""

  return DiscogsUtils.getReleaseItems(0, cursor)
}

const setReleasesCache = (releases: InstanceReleaseList) => {
  let CACHE_KEY = "releases"

  Cache.setItem(CACHE_KEY, JSON.stringify(releases))
}

const getReleasesCache = (): InstanceReleaseList | null => {
  let CACHE_KEY = "releases"

  const json = Cache.getItem(CACHE_KEY)
  if (json) {
    return JSON.parse(json)
  } else {
    return null
  }
}

const CACHE_KEY_CURSOR = "releases-nextPage"

export default function Releases() {
  const buildData = useStaticQuery(graphql`
    query {
      releases {
        nextPage
        items {
          id
          title
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          data {
            imageUrl
            year
            artists {
              id
              name
            }
            labels {
              id
              name
            }
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let initReleases = buildData.releases.items
  let initNextPage = buildData.releases.nextPage

  const [releases, setReleases] = useState<InstanceReleaseList>(() => {
    if (typeof sessionStorage !== "undefined") {
      const cacheReleases = getReleasesCache()
      if (cacheReleases !== null) {
        return cacheReleases
      }
    }
    return initReleases
  })
  const [nextPage, setNextPage] = useState<string>(() => {
    if (typeof sessionStorage !== "undefined") {
      const cacheCursor = Cache.getItem(CACHE_KEY_CURSOR)
      if (cacheCursor !== null) {
        return cacheCursor
      }
    }
    return initNextPage
  })
  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const [
    selectedRelease,
    setSelectedRelease,
  ] = useState<InstanceReleaseEntry | null>(null)
  const [loadingItem, setLoadingItem] = useState<boolean>(false)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"))

  const siteTitle = buildData.site.siteMetadata.title

  const loadMoreHandler = useCallback(async () => {
    setLoadingPage(true)
    const opts = { cursor: nextPage }
    const response = await getReleases(opts)

    setReleases(prev => {
      const items = [...prev, ...response.items]
      setReleasesCache(items)
      return items
    })
    setNextPage(response.cursor)
    Cache.setItem(CACHE_KEY_CURSOR, response.cursor)

    setLoadingPage(false)
  }, [nextPage])

  const loadReleaseHandler = useCallback(
    async (id: string) => {
      if (!loadingItem) {
        setLoadingItem(true)
        const itemKey = "item-" + id
        let release = Cache.getItemFromCache(itemKey) as InstanceReleaseEntry
        if (!release) {
          release = await DiscogsUtils.getReleaseItem(id)
          Cache.saveItemToCache(itemKey, release)
        }
        setLoadingItem(false)
        setSelectedRelease(release)
      }
    },
    [loadingItem]
  )

  const closeDialogHandler = useCallback(() => {
    setSelectedRelease(null)
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${siteTitle} - Releases`}</title>
        <meta
          name="description"
          content="View the distinct releases in my record collection."
        />
      </Helmet>
      <Page navTitle="Releases">
        <LoadingBackdrop open={loadingItem} />
        <GridLayout
          type="releases"
          items={releases}
          nextPage={nextPage}
          loadMoreHandler={loadMoreHandler}
          loadingPage={loadingPage}
          loadItemHandler={loadReleaseHandler}
          closeDialogHandler={closeDialogHandler}
          selectedItem={selectedRelease}
          showInstanceLink={true}
          isLoadingItem={loadingItem}
          useFullDialog={isSmallScreen}
        />
        <ItemDrawer
          type="releases"
          item={selectedRelease}
          closeHandler={closeDialogHandler}
          useInstanceLink={true}
        />
      </Page>
    </React.Fragment>
  )
}
